import consumer from "./consumer"

consumer.subscriptions.create("PrintChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to PrintChannel");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log(data);
    // Called when there's incoming data on the websocket for this channel
    let display = document.getElementById('print');

    display.innerHTML +=
      "<h2>" + data.name + "</h2>";
      display
      display.innerHTML +=
      "<p>" + data.id + "</p>";

    let docDefinition = {
      pageSize: {
        width: 200,
        height: 125
      },
      pageMargins: 5,
      content: [],
      styles: {
        name: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
        company: {
          fontSize: 14,
          bold: false,
          alignment: 'center'
        }
      }
    };

    let attendant = {
      text: '',
      style: 'name',
      margin: [0, 15, 0, 0]
    };

    let organisation = {
      text: '',
      style: 'company',
      margin: [0, 10, 0, 0]
    };

    attendant.text = data.name.toUpperCase();
    organisation.text = data.company.toUpperCase();
    docDefinition.content.push(attendant);
    docDefinition.content.push(organisation);
    display = docDefinition
    console.log(display);
    pdfMake.createPdf(docDefinition).print();
  }
});
